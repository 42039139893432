<template>
  <KList nav>
    <KListSubheader v-if="!rail" class="font-weight-bold text-grey">
      {{ $t('管理機能TOP.管理センター') }}
    </KListSubheader>

    <KListItem exact prepend-icon="$mdiHome" :to="localePath('/admin')">
      <template #title>
        <span class="font-weight-bold">
          {{ $t('管理機能TOP.ホーム') }}
        </span>
      </template>
    </KListItem>

    <div v-if="!rail">
      <KListGroup
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        color="white"
        :prepend-icon="item.icon"
      >
        <template #activator="{ props }">
          <KListItem v-bind="props" :value="`group-${item.title}`">
            <template #title>
              <span class="font-weight-bold text-wrap">
                {{ item.title }}
              </span>
            </template>
          </KListItem>
        </template>
        <KListItem
          v-for="(subitem, subitemIndex) in item.items"
          :key="`${itemIndex}-${subitemIndex}`"
          density="compact"
          :exact="subitem.exact"
          :to="localePath(subitem.to)"
          :value="subitem.title"
        >
          <template #title>
            <span class="font-weight-bold text-wrap">
              {{ subitem.title }}
            </span>
          </template>
        </KListItem>
      </KListGroup>
    </div>
    <div v-else>
      <KListItem
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        color="white"
        :prepend-icon="item.icon"
        :title="item.title"
        :value="`group-${item.title}`"
      />
    </div>

    <KDivider />

    <KListItem
      class="mt-3"
      prepend-icon="$mdiArrowRight"
      :to="localePath('/dashboard')"
    >
      <template #title>
        <span class="font-weight-bold">
          {{ $t('管理機能TOP.ダッシュボードへ戻る') }}
        </span>
      </template>
    </KListItem>
  </KList>
</template>

<script setup lang="ts">
import type { TranslateResult } from 'vue-i18n'

import { useSessionStore } from '~/stores/session'

interface DrawerSubItem {
  title: TranslateResult
  to: string
  exact: boolean
}

interface DrawerItem {
  icon: string
  title: TranslateResult
  items: DrawerSubItem[]
}

withDefaults(
  defineProps<{
    rail?: boolean
  }>(),
  {
    rail: false,
  }
)

const i18n = useI18n()
const localePath = useLocalePath()
const sessionStore = useSessionStore()

const userItem = computed(() => {
  if (!sessionStore.isUserAdmin && !sessionStore.isTeamAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.ユーザー'),
    icon: '$mdiAccountMultiple',
    items: [],
  }
  if (sessionStore.isUserAdmin) {
    item.items.push(
      {
        title: i18n.t('管理機能TOP.ユーザー'),
        to: '/admin/users',
        exact: false,
      },
      {
        title: '役職',
        to: '/admin/grades',
        exact: false,
      },
      {
        title: '代理申請',
        to: '/admin/proxyApplicants',
        exact: false,
      },
      {
        title: '代理承認',
        to: '/admin/proxyApprovers',
        exact: false,
      }
    )
  }
  if (sessionStore.isTeamAdmin) {
    item.items.push({
      title: i18n.t('管理機能TOP.組織図'),
      to: '/admin/organizations',
      exact: false,
    })
  }
  if (sessionStore.isUserAdmin) {
    item.items.push({
      title: '招待メールの有効期間',
      to: '/admin/invitationSetting',
      exact: false,
    })
  }
  return item
})

const workflowItem = computed(() => {
  if (!sessionStore.isWorkflowAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.ワークフロー'),
    icon: '$mdiFolder',
    items: [],
  }
  if (sessionStore.isWorkflowAdmin) {
    item.items.push(
      {
        title: i18n.t('管理機能TOP.ワークフロー'),
        to: '/admin/workflows',
        exact: false,
      },
      {
        title: 'ワークフローのカテゴリ',
        to: '/admin/categories',
        exact: false,
      },
      {
        title: '経路',
        to: '/admin/routes',
        exact: true,
      },
      {
        title: '採番グループ',
        to: '/admin/numberingGroups',
        exact: false,
      },
      {
        title: 'サブステータス',
        to: '/admin/subStatuses',
        exact: false,
      },
      {
        title: 'フォルダ',
        to: '/admin/folders',
        exact: false,
      },
      {
        title: '自動計算用グローバル変数',
        to: '/admin/calculationVariables',
        exact: false,
      },
      {
        title: 'パイプライン',
        to: '/admin/pipelines',
        exact: false,
      }
    )
  }
  return item
})

const masterItem = computed(() => {
  if (!sessionStore.isMasterAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.汎用マスタ'),
    icon: '$mdiDatabase',
    items: [],
  }
  item.items.push({
    title: i18n.t('管理機能TOP.汎用マスタ'),
    to: '/admin/masters',
    exact: false,
  })
  return item
})

const labelItem = computed(() => {
  if (!sessionStore.isLabelAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.ラベル'),
    icon: '$mdiLabel',
    items: [],
  }
  item.items.push({
    title: i18n.t('管理機能TOP.ラベル'),
    to: '/admin/labels',
    exact: false,
  })
  return item
})

const automationItem = computed(() => {
  if (!sessionStore.isAutomationAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.オートメーション'),
    icon: '$mdiLightningBolt',
    items: [],
  }
  item.items.push({
    title: i18n.t('管理機能TOP.オートメーション'),
    to: '/admin/automations',
    exact: false,
  })
  return item
})

const tenantItem = computed(() => {
  if (!sessionStore.isTenantAdmin && !sessionStore.isBillingAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.テナント'),
    icon: '$mdiDomain',
    items: [],
  }
  if (sessionStore.isTenantAdmin) {
    item.items.push(
      {
        title: 'テナント情報',
        to: '/admin/tenant',
        exact: true,
      },
      {
        title: 'テナントID（サブドメイン）',
        to: '/admin/tenant/subdomain',
        exact: false,
      },
      {
        title: '会社からのお知らせ',
        to: '/admin/tenant/news',
        exact: false,
      },
      {
        title: '作業中モード',
        to: '/admin/tenant/working',
        exact: false,
      },
      {
        title: '通知設定の初期値',
        to: '/admin/tenant/notifications',
        exact: false,
      },
      {
        title: 'リマインド通知の設定',
        to: '/admin/tenant/remind',
        exact: false,
      },
      {
        title: 'リモートアシスタント',
        to: '/admin/tenant/assistants',
        exact: false,
      },
      {
        title: 'バックアップ',
        to: '/admin/tenant/backups',
        exact: false,
      }
    )
  }
  if (sessionStore.isBillingAdmin) {
    item.items.push({
      title: i18n.t('管理機能TOP.プラン・お支払い'),
      to: '/admin/billing',
      exact: false,
    })
  }
  if (sessionStore.isTenantAdmin) {
    item.items.push({
      title: 'その他の設定',
      to: '/admin/tenant/settings',
      exact: false,
    })
  }
  return item
})

const ticketItem = computed(() => {
  if (!sessionStore.isTicketWriteAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.チケット'),
    icon: '$mdiFile',
    items: [
      {
        title: 'マイチケットの引き継ぎ',
        to: '/admin/tickets/transfer/myticket',
        exact: false,
      },
      {
        title: '承認リクエストの引き継ぎ',
        to: '/admin/tickets/transfer/task',
        exact: false,
      },
      {
        title: '閲覧権限の一括付与',
        to: '/admin/tickets/viewer',
        exact: false,
      },
      {
        title: '閲覧権限の一括削除',
        to: '/admin/tickets/viewer/delete',
        exact: false,
      },
      {
        title: 'クリーンアップ',
        to: '/admin/tickets/clean',
        exact: false,
      },
    ],
  }
  return item
})

const connectionItem = computed(() => {
  if (!sessionStore.isConnectionAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.外部連携'),
    icon: '$mdiApi',
    items: [
      {
        title: 'Microsoft Teams',
        to: '/admin/connections/microsoftTeams',
        exact: false,
      },
      {
        title: 'Slack',
        to: '/admin/connections/slack',
        exact: false,
      },
      {
        title: 'Chatwork',
        to: '/admin/connections/chatwork',
        exact: false,
      },
      {
        title: 'LINE WORKS',
        to: '/admin/connections/lineWorks',
        exact: false,
      },
      {
        title: 'Google Workspaceディレクトリ',
        to: '/admin/connections/gwsDirectory',
        exact: false,
      },
      {
        title: 'Googleドライブ',
        to: '/admin/connections/googleDrive',
        exact: false,
      },
      {
        title: 'Box',
        to: '/admin/connections/box',
        exact: false,
      },
      {
        title: 'ClimberCloud',
        to: '/admin/connections/climberCloud',
        exact: false,
      },
      {
        title: 'Webhook',
        to: '/admin/connections/webhook',
        exact: false,
      },
      {
        title: 'サービスアカウント',
        to: '/admin/connections/serviceAccounts',
        exact: false,
      },
    ],
  }
  return item
})

const securityItem = computed(() => {
  if (
    !sessionStore.isRoleAdmin &&
    !sessionStore.isSecurityAdmin &&
    !sessionStore.isAuditAdmin
  ) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.セキュリティ'),
    icon: '$mdiShieldCheck',
    items: [],
  }
  if (sessionStore.isRoleAdmin) {
    item.items.push({
      title: i18n.t('管理機能TOP.管理者ロール'),
      to: '/admin/roles',
      exact: false,
    })
  }
  if (sessionStore.isSecurityAdmin) {
    item.items.push(
      {
        title: '認証方式',
        to: '/admin/authentication',
        exact: false,
      },
      {
        title: 'IPアドレス制限',
        to: '/admin/ip',
        exact: false,
      },
      {
        title: '招待可能なドメイン制限',
        to: '/admin/invitationDomains',
        exact: false,
      }
    )
  }
  if (sessionStore.isAuditAdmin) {
    item.items.push({
      title: i18n.t('管理機能TOP.監査ログ'),
      to: '/admin/audit',
      exact: false,
    })
  }
  return item
})

const statsItem = computed(() => {
  if (!sessionStore.isStatsAdmin) {
    return null
  }
  const item: DrawerItem = {
    title: i18n.t('管理機能TOP.分析レポート'),
    icon: '$mdiChartBox',
    items: [
      {
        title: i18n.t('管理機能TOP.分析レポート'),
        to: '/admin/stats',
        exact: true,
      },
    ],
  }
  return item
})

const items = computed(() => {
  const items: (DrawerItem | null)[] = []
  items.push(
    tenantItem.value,
    userItem.value,
    workflowItem.value,
    masterItem.value,
    labelItem.value,
    automationItem.value,
    ticketItem.value,
    statsItem.value,
    connectionItem.value,
    securityItem.value
  )
  return items.filter((item) => !!item) as DrawerItem[]
})
</script>

<style scoped></style>
